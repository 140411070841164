import React, { useState } from 'react';
import { RoomCard } from './RoomCards';
import { cva } from 'class-variance-authority';

export default function RoomItem ({
  deleteRoom,
  room,
  bed,
  allBeds,
  roomIndex,
  possibility,
  handleSelect,
  isSelected,
  selected,
  filters,
  selectedRoomsFromPromotionSelect,
  promoList,
  nRoomIndex,
  setSelectedPromotionSupplemnts,
  selectedPromotionSupplemnts
}) {
  const [currentBedIndex, setCurrentBedIndex] = useState(0);

  const card = cva([
    'relative isolate w-full bg-white border-2 rounded-lg shadow-k py-5 px-6 cursor-pointer',
    'overflow-hidden',
  ], {
    variants: {
      selected: {
        true: 'border-k-orange',
        false: 'border-transparent'
      }
    }
  });

  return (
    <div
      className={card({selected: isSelected})}
      onClick={() => {
        if (!isSelected) handleSelect(currentBedIndex);
      }}
    >
      {possibility?.attachments?.length > 0 && (
        <>
          <img
            src={possibility?.attachments[0]?.url ?? '/img/hotel-bg-placeholder.png'}
            onError={ev => ev.target.src = '/img/hotel-bg-placeholder.png'}
            className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm rounded-lg"
          />

          <div className="absolute inset-0 -z-10">
            <div className="h-full w-full bg-gradient-to-l from-white/75 to-white transition-opacity"/>
          </div>
        </>
      )}

      <div className="relative flex space-x-5 items-center">
        <RoomCard
          roomIndex={roomIndex}
          deleteRoom={deleteRoom}
          allBeds={allBeds}
          bed={bed ?? allBeds[currentBedIndex]}
          room={room}
          possibility={possibility}
          changeSelectedBed={(index) => {
            setCurrentBedIndex(index);
            handleSelect(index);
          }}
          selectedRoomsFromPromotionSelect={selectedRoomsFromPromotionSelect}
          selected={selected}
          filters={filters}
          isSelected={isSelected}
          currentBedIndex={currentBedIndex}
          promoList={promoList}
          nRoomIndex={nRoomIndex}
          setSelectedPromotionSupplemnts={setSelectedPromotionSupplemnts}
          selectedPromotionSupplemnts={selectedPromotionSupplemnts}
        />

        <div className="text-xl">
          {isSelected ? (
            <i className="far fa-check-circle text-k-orange"/>
          ) : (
            <i className="far fa-circle text-gray-400"/>
          )}
        </div>
      </div>
    </div>
  );
}
